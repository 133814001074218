import { ApolloClient, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import errorLink from '~/client/apollo/clientError';
import cacheOptions from '~/client/apollo/cacheOptions';

const httpLink = createUploadLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
});

const client = (cache?: NormalizedCacheObject) => new ApolloClient({
  ssrMode: false,
  link: from([errorLink, httpLink]),
  connectToDevTools: true,
  cache: cache
    ? new InMemoryCache(cacheOptions).restore(cache)
    : new InMemoryCache(cacheOptions),
});

export default client;
