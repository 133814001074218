import { InMemoryCacheConfig } from '@apollo/client';

const cacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        notifications: {
          merge(_, incoming) {
            return incoming;
          },
        },
        publicSubSectionProducts: {
          keyArgs: [],
          merge(existing, incoming) {
            return [...(existing ?? []), ...incoming];
          },
        },
      },
    },
    ProductModifier: {
      keyFields: false,
    },
    PublicField: {
      keyFields: false,
    },
    NotificationEntityWithCode: {
      keyFields: false,
    },
    // Brand: {
    //   keyFields: false,
    // },
    Catalog: {
      fields: {
        // sections: {
        //   merge(_, incoming) {
        //     return incoming;
        //   },
        // },
        parent: {
          merge(existing, incoming) {
            if (!existing) return incoming;
            if (!incoming) return existing;

            return { ...existing, ...incoming };
          },
        },
      },
    },
    // Section: {
    //   fields: {
    //     // columns: {
    //     //   merge(_, incoming) {
    //     //     return incoming;
    //     //   },
    //     // },
    //     products: {
    //       merge(_, incoming) {
    //         return incoming;
    //       },
    //     },
    //   },
    // },
    Product: {
      fields: {
        modifiers: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    Offer: {
      fields: {
        groups: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    PublicProfile: {
      fields: {
        token: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
} as InMemoryCacheConfig;

export default cacheOptions;
