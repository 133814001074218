import { atom } from 'nanostores';
import { nanoid } from 'nanoid';
import { EventNotification, UserNotification } from './types';

export const burnout = atom<number>(10000); // ms

export const setBurnout = (value: number) => {
  burnout.set(value);
};

export const notifications = atom<UserNotification[]>([]);

export const addNotification = (notification: EventNotification) => {
  const message = { ...notification } as EventNotification;
  if (!notification.id) {
    message.id = nanoid();
  }

  // check for duplicate ids
  const includesNotification = notifications.get().some((n) => n.id === message.id);

  if (!includesNotification) {
    // new notification always first
    notifications.set([message as UserNotification, ...notifications.get()]);
  }
};

export const removeNotification = (id: string) => {
  notifications.set(notifications.get().filter((notification) => notification.id !== id));
};

export const getNotifications = () => notifications.get();
